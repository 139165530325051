import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import axios from "axios";
import config from "../config.js";
import Authentication from '../Authentication';
import ReactQuill from 'react-quill-new';
import 'react-quill-new/dist/quill.snow.css';
import "./style.css";

class Products extends Component {
  constructor(props) {
    super(props);
    this.state = {
      products: [],
      isEditMode: false,
      showOtherscustomxContent: false,
      showOthersexsContent: false,
      showOthersmumbimboosterContent: false,
      showOthersmumsteelworkforinventorContent: false,
      showOthersmapeditContent: false,
      showOthersmumpdmboosterContent: false,
      showOthersmumqtoboosterContent: false,
      showOthersmummultitoolforinventorContent: false,
      showOthersmumpraxispaketstahlbauContent: false,
      showOthersmumautocadboosterContent: false,
      showOthersmummaterialbrowserforinventorContent: false,
      showMainContent: true,
      currentView: "main",
      selectedYear: 2025,
      selectedLanguage: "Deutsch",
      selectedValues: {},
      selectedCategory: "Release",
      editedProducts: {},
      softwares: [
        "customx",
        "exs",
        "ginfo",
        "mapedit",
        "mumautocadbooster",
        "mumbimbooster",
        "mummaterialbrowserforinventor",
        "mummultitoolforinventor",
        "mumpdmbooster",
        "mumpraxispaketstahlbau",
        "mumqtobooster",
        "mumsteelworkforinventor",
      ],
      pagelanguages: ["Deutsch", "English"],
      selectedPageLanguage: "Deutsch",
      isAdmin: this.props.auth.isAdmin || false,
      showContentCategory: false,
      isReleaseNotesOpen: false,
      currentReleaseNotes: "",
      editingProductId: null,
      originalReleaseNotes: "",
      sortOrder: { column: null, direction: 'asc' },
      categoryDescriptions: {},
    };
    this.years = [2025, 2024, 2023, 2022];
    this.filteredYears = {
      mumbimbooster: this.years.filter((year) => year !== 2022),
    };
    this.categories = ["Release", "Updates", "Module", "Documentation"];
    this.languages = ["Deutsch", "English"];
    this.authentication = new Authentication();
  }

  handlePageLanguageChange = (event) => {
    this.setState({ selectedPageLanguage: event.target.value });
  };

  setSelectedValue = (key, value, productId) => {
    this.setState(
      (prevState) => ({
        selectedValues: {
          ...prevState.selectedValues,
          [productId]: {
            ...prevState.selectedValues[productId],
            [key]: value,
          },
        },
      }),
      () => {
        //console.log(`Selected ${key} changed:`, this.state.selectedValues);
      }
    );
  };
  setSelectedYear = (year, productId) => {
    this.setState(
      (prevState) => ({
        selectedValues: {
          ...prevState.selectedValues,
          [productId]: {
            ...prevState.selectedValues[productId],
            year,
          },
        },
      }),
      () => {
        //console.log("Selected years changed:", this.state.selectedValues);
      }
    );
  };

  setSelectedLanguage = (language, productId) => {
    this.setState(
      (prevState) => ({
        selectedValues: {
          ...prevState.selectedValues,
          [productId]: {
            ...prevState.selectedValues[productId],
            language,
          },
        },
      }),
      () => {
        //console.log("Selected languages changed:", this.state.selectedValues);
      }
    );
  };

  setSelectedCategory = (category) => {
    this.setState({ selectedCategory: category });
  };
  handleReleaseNotesClick = (releaseNotes, productId) => {
    this.setState({
      isReleaseNotesOpen: true,
      currentReleaseNotes: releaseNotes,
      editingProductId: productId,
      isAdmin: this.props.auth.isAdmin || false,
    });
  };
  handleCategoryDescriptionChange = (e, category, software, year) => {
    const newDescription = e.target.value;

    const year_category = `${year}_${category}`;

    this.setState((prevState) => ({
        categoryDescriptions: {
            ...prevState.categoryDescriptions,
            [software]: {
                ...prevState.categoryDescriptions[software],
                [year_category]: newDescription,
            },
        },
    }));
  };
  handleCategoryDescriptionSave = async (category, software, year) => {
    if (!this.isAdmin()) return;

    if (!software || !year || !category) {
        window.alert('Software, Year, or Category is undefined');
        return;
    }

    const year_category = `${year}_${category}`;
    const description = this.state.categoryDescriptions?.[software]?.[year_category] || "";

    if (description.trim() === "") {
        window.alert('Description cannot be empty');
        return;
    }

    try {
        const auth = new Authentication();
        await auth.login();

        const idToken = sessionStorage.getItem("idToken");
        const headers = { Authorization: `Bearer ${idToken}` };


        await axios.post(
            `${config.api.invokeUrl}/CategoryDesc`,
            { description, software, year_category },
            { headers }
        );

        window.alert('Text saved successfully!');
        await this.fetchCategoryDescriptions();
    } catch (error) {
        window.alert('Error saving category description: ' + (error.response ? error.response.data : error.message));
    }
  };

  fetchCategoryDescriptions = async () => {
    try {
        const auth = new Authentication();
        await auth.login();

        const idToken = sessionStorage.getItem("idToken");
        const headers = { Authorization: `Bearer ${idToken}` };

        const response = await axios.get(`${config.api.invokeUrl}/CategoryDesc`, { headers });

        const data = response.data;

        if (!Array.isArray(data)) {
          window.alert("Unexpected response format");
          return;
        }

        const categoryDescriptions = data.reduce((acc, item) => {
            const { description, software, year_category } = item;

            if (!acc[software]) acc[software] = {};
            acc[software][year_category] = description;

            return acc;
        }, {});

        this.setState({ categoryDescriptions });
    } catch (error) {
        window.alert(`Error fetching category descriptions: ${error.message}`);
    }
  };
  
  handleClosePopup = () => {
    this.setState({
      isReleaseNotesOpen: false,
      currentReleaseNotes: "",
      isAdmin: false,
    });
  };
  
  handleEditReleaseNotesClick = () => {
    this.setState((prevState) => ({
      isEditMode: true,
      originalReleaseNotes: prevState.currentReleaseNotes,
    }));
  };
  
  handleSaveReleaseNotes = async (newNotes) => {
    const { products, editingProductId, isAdmin } = this.state;
    const auth = new Authentication();
  
    try {  
      await auth.login();
      const idToken = sessionStorage.getItem("idToken");
      const headers = { Authorization: `Bearer ${idToken}` };
  
      const releaseNotes = newNotes !== undefined ? newNotes : '';  
      const productToUpdate = products.find(product => product.id === editingProductId);
  
      if (!productToUpdate) {
        console.error("Product not found with ID:", editingProductId);
        throw new Error('Product not found');
      }
  
      const updatedProduct = {
        ...productToUpdate,
        releaseNotes: releaseNotes,
      };
      await axios.patch(
        `${config.api.invokeUrl}/Products/${productToUpdate.id}`,
        updatedProduct,
        { headers }
      );
  
      this.setState(prevState => ({
        products: prevState.products.map(product => {
          if (product.id === productToUpdate.id) {
            return { ...product, ...updatedProduct };
          }
          return product;
        }),
        isEditMode: false,
        currentReleaseNotes: releaseNotes,
        isReleaseNotesOpen: true,
        editedProducts: {},
        editingProductId: null,
        isAdmin,
        originalReleaseNotes: '',
      }));
    } catch (err) {
      console.error(`Error updating product: ${err}`);
      window.alert(`Error updating product: ${err}`);
    }
  };

  handleCancelReleaseNotesEdit = () => {
    this.setState(prevState => ({
      isEditMode: false,
      currentReleaseNotes: prevState.originalReleaseNotes,
      originalReleaseNotes: '',
    }));
  };
  renderReleaseNotesPopup = () => {
    const { isReleaseNotesOpen, currentReleaseNotes, isAdmin, isEditMode  } = this.state;

    if (!isReleaseNotesOpen) return null;
    let buttonContainerClass = "button-container-releasenotes";
    if (!isAdmin && !isEditMode) {
      buttonContainerClass = "button-container-releasenotes-center";
    }
    return (
      <div className="release-notes-popup">
        <div className="popup-content">
          {isEditMode ? (
            <ReactQuill
              theme="snow"
              value={currentReleaseNotes}
              onChange={(content) =>
                this.setState({ currentReleaseNotes: content })
              }
            />
          ) : (
            <div className="ql-container ql-snow">
              <div className="ql-editor" dangerouslySetInnerHTML={{ __html: currentReleaseNotes }} />
            </div>
          )}
          <div className={buttonContainerClass}>
            {isAdmin && !isEditMode && (
              <button className="releasenotes-button" onClick={this.handleEditReleaseNotesClick}>
                Edit
              </button>
            )}

            {isEditMode && (
              <>
                <button className="releasenotes-button" onClick={() => this.handleSaveReleaseNotes(currentReleaseNotes)}>
                  Save
                </button>
                <button className="releasenotes-button" onClick={this.handleCancelReleaseNotesEdit}>
                  Cancel
                </button>
              </>
            )}

            <button className="releasenotes-button" onClick={this.handleClosePopup}>
              Close
            </button>
          </div>  
        </div>
      </div>
    );
  };

  handleSort = (column) => {
    const { products, sortOrder } = this.state;
    const newDirection = sortOrder.direction === 'asc' ? 'desc' : 'asc';
    const sortedProducts = [...products].sort((a, b) => {
        if (column === 'date') {
            const dateA = new Date(a.uploadDate);
            const dateB = new Date(b.uploadDate);
            return newDirection === 'asc' ? dateA - dateB : dateB - dateA;
        } else if (column === 'name') {
            const nameA = a.fullname ? a.fullname.toLowerCase() : '';
            const nameB = b.fullname ? b.fullname.toLowerCase() : '';
            return newDirection === 'asc'
                ? nameA.localeCompare(nameB)
                : nameB.localeCompare(nameA);
        }
        return 0;
    });
    this.setState({
        products: sortedProducts,
        sortOrder: { column, direction: newDirection },
    });
  };
  handleLoginClick = async () => {
    try {
      await this.authentication.clearAllStorage();
      
      const idTokenPayload = await this.authentication.login();
      
      if (!idTokenPayload) {
        window.location.href = this.authentication.loginUrl;
      } else {
        await this.props.handleLogin();
        
        await this.fetchProducts();
        await this.fetchCategoryDescriptions();
      }
    } catch (error) {
      console.error('Login error:', error);
      await this.authentication.clearAllStorage();
      window.location.href = this.authentication.loginUrl;
    }
  };

  handleLogoutClick = async () => {
    try {
      this.setState({
        products: [],
        isEditMode: false,
        selectedValues: {},
        categoryDescriptions: {},
        editedProducts: {},
        showMainContent: true,
        currentView: "main"
      });

      await this.props.handleLogout();
      
      window.location.reload();
    } catch (error) {
      console.error('Logout error:', error);
      window.location.reload();
    }
  };
  
  async fetchProducts() {
    try {
      this.setState({ products: [] });
      
      let headers = {};
      if (this.props.auth.isAuthenticated) {
        const idToken = sessionStorage.getItem("idToken");
        if (!idToken) {
          throw new Error('No valid authentication token found');
        }
        headers = {
          Authorization: `Bearer ${idToken}`,
        };
      }

      const url = `${config.api.invokeUrl}/Products`;
      const res = await axios.get(url, { headers });
      
      if (!Array.isArray(res.data)) {
        throw new Error('Invalid product data received');
      }

      const products = res.data;
      const selectedValues = {};
      
      products.forEach((product) => {
        selectedValues[product.id] = {
          year: this.state.selectedYear,
          language: this.state.selectedLanguage,
          software: product.software,
          category: product.category || "",
          fullname: product.fullname || ""
        };
      });

      this.setState({ products, selectedValues });
    } catch (err) {
      if (err.response?.status === 401) {
        this.setState({ products: [] });
        if (this.props.auth.isAuthenticated) {
          await this.authentication.clearAllStorage();
          window.location.reload();
        }
      } else {
        console.error('Error fetching products:', err);
        this.setState({ products: [] });
      }
    }
  }

  async componentDidMount() {
    try {
      const idToken = sessionStorage.getItem("idToken");
      if (idToken) {
        const auth = new Authentication();
        const isValid = await auth.login();
        if (!isValid) {
          await auth.clearAllStorage();
          window.location.reload();
          return;
        }
      }

      await this.fetchProducts();
      this.checkAndSetContentCategoryVisibility();
      if (idToken) {
        await this.fetchCategoryDescriptions();
      }
    } catch (error) {
      console.error('Error during component initialization:', error);
      const auth = new Authentication();
      await auth.clearAllStorage();
      window.location.reload();
    }
  }

  async componentDidUpdate(prevProps, prevState) {
    if (prevProps.auth.userLicenses !== this.props.auth.userLicenses) {
      this.checkAndSetContentCategoryVisibility();
    }
    if (
      prevState.selectedCategory !== this.state.selectedCategory ||
      prevState.selectedYear !== this.state.selectedYear ||
      prevState.software !== this.state.software
    ) {
      const idToken = sessionStorage.getItem("idToken");
      if (idToken) {
        await this.fetchCategoryDescriptions();
      }
    }
  }

  isAdmin = () => {
    return this.props.auth.isAdmin;
  };

  checkAndSetContentCategoryVisibility() {
    const { userLicenses } = this.props.auth;
    if (userLicenses.includes("Bim Booster")) {
      this.setState({ showContentCategory: true });
    } else {
      this.setState({ showContentCategory: false });
    }
  }

  handleLearnMoreClick = (software) => {
    const { selectedPageLanguage } = this.state;
    const softwareUrls = {
      customx: {
          Deutsch: "https://www.customx.de",
          English: "https://www.customx.de/en",
      },
      exs: {
          Deutsch: "https://www.mum.de/produkte/exs",
          English: "https://products.mum.de/exs-electrical-cad-software",
      },
      ginfo: {
          Deutsch: "https://www.mum.de/produkte/g-info",
          English: "https://www.mum.de/produkte/g-info",
      },
      mapedit: {
          Deutsch: "https://www.mum.de/produkte/mum-mapedit",
          English: "https://products.mum.de/mapedit-for-infrastructure-data",
      },
      mumbimbooster: {
          Deutsch: "https://www.mum.de/produkte/bim-booster",
          English: "https://products.mum.de/bim-booster-for-autodesk-revit",
      },
      mumpdmbooster: {
          Deutsch: "https://www.mum.de/produkte/mum-pdm-booster",
          English: "https://products.mum.de/pdm-booster-for-autodesk-vault",
      },
      mumqtobooster: {
          Deutsch: "https://www.mum.de/produkte/qto-booster",
          English: "https://www.mum.de/produkte/qto-booster",
      },
      mummultitoolforinventor: {
          Deutsch: "https://www.mum.de/produkte/mum-multitool-for-inventor",
          English: "https://products.mum.de/mum-multitool-for-autodesk-inventor",
      },
      mumsteelworkforinventor: {
          Deutsch: "https://www.mum.de/produkte/mum-steelwork-for-autodesk-inventor",
          English: "https://products.mum.de/mum-steelwork-for-inventor",
      },
      mumpraxispaketstahlbau: {
          Deutsch: "https://www.mum.de/produkte/mum-praxispaket-stahlbau",
          English: "https://www.mum.de/produkte/mum-praxispaket-stahlbau",
      },
      mummaterialbrowserforinventor: {
          Deutsch: "https://www.mum.de/produkte/mum-material-browser-fuer-inventor",
          English: "https://www.mum.de/produkte/mum-material-browser-fuer-inventor",
      },
      mumautocadbooster: {
          Deutsch: "https://www.mum.de/produkte/mum-acad-toolbox",
          English: "https://www.mum.de/produkte/mum-acad-toolbox",
      }
    };

    if (softwareUrls.hasOwnProperty(software.toLowerCase())) {
      const selectedUrl = softwareUrls[software.toLowerCase()][selectedPageLanguage];
      if (selectedUrl) {
        window.open(selectedUrl, "_blank");
      } else {
          console.error(`No URL defined for language: ${selectedPageLanguage} for ${software}`);
          alert(`No URL defined for this software in ${selectedPageLanguage}`);
      }
    } else {
      console.error(`No URL defined for software: ${software}`);
      alert(`No URL defined for this software`);
    }
  };
  handleDownloadClick = async (product) => {
    if (!this.props.auth.isAuthenticated) {
      window.location.href = config.LOGIN_URL;
      return;
    }

    const productId = product.id;

    if (!productId || !this.state.selectedValues[productId]) {
      console.error(
        "Invalid product or selected values.",
        productId,
        this.state.selectedValues[productId]
      );
      return;
    }

    const { year, language, software } = this.state.selectedValues[productId];

    if (!year || !language || !software) {
      console.error(
        "Invalid selection: Year, language, or software is not selected."
      );
      return;
    }
    const auth = new Authentication();
    try {
      const filteredProducts = this.state.products.filter((p) => {
        return p.software === software && p.year === year.toString() && p.language === language;
      });

      if (filteredProducts.length === 0) {
        console.error(
          "No matching product found for the selected year, language, and software."
        );
        alert(
          "No matching product found for the selected year, language, and software."
        );
        return;
      }

      const filteredProduct = filteredProducts[0];

      const session = await auth.login();
      const idToken = sessionStorage.getItem("idToken");
      const email = session["email"];
      const userId = session["sub"];
      const headers = {
        Authorization: `Bearer ${idToken}`,
        "X-Amz-User-Id": userId,
      };

      if (email) {
        const downloadActivityData = {
          Username: email,
          Timestamp: new Date().toISOString(),
          ProductID: filteredProduct.id,
          Fullname: filteredProduct.software,
        };

        await axios.post(`${config.api.invokeUrl}/Activity`, downloadActivityData, { headers });

        const response = await axios.get(`${config.api.invokeUrl}/GetSignedUrl/${filteredProduct.id}`, { headers });
    
        const presignedUrl = response.data.url;
        window.location.href = presignedUrl;
      } else {
        console.error("Email is undefined.");
      }
    } catch (error) {
      console.error("Error during the download process:", error);
    }
  };

  handleDownloadtableClick = async (
    product,
    /* selectedYear,
    selectedCategory */
  ) => {
    if (!this.props.auth.isAuthenticated) {
      window.location.href = config.LOGIN_URL;
      return;
    }
    const productId = product.id;
    if (!productId || !this.state.selectedValues[productId]) {
      console.error(
        "Invalid product or selected values.",
        productId,
        this.state.selectedValues[productId]
      );
      return;
    }

    //const { software } = this.state.selectedValues[productId];
        
    const auth = new Authentication();
    try {
      /* const filteredProducts = this.state.products.filter((p) => {
        if (software === "mapedit") {
          return p.software === software && p.category === selectedCategory;
        }
        return (
          p.software === software &&
          p.year === selectedYear.toString() &&
          p.category === selectedCategory        );
      });

      if (filteredProducts.length === 0) {
        console.error(
          "No matching product found for the selected software, year, category, and fullname."
        );
        alert("No matching product found for the selected software, year, category, and fullname.");
        return;
      }      
      const filteredProduct = filteredProducts[0]; */

      const session = await auth.login();
      const idToken = sessionStorage.getItem("idToken");
      const email = session["email"];
      const userId = session["sub"];
      const headers = {
        Authorization: `Bearer ${idToken}`,
        "X-Amz-User-Id": userId,
      };

      if (email) {

        const downloadActivityData = {
          Username: email,
          Timestamp: new Date().toISOString(),
          ProductID: product.id,
          Fullname: product.software,
        };

        await axios.post(
          `${config.api.invokeUrl}/Activity`,
          downloadActivityData,
          { headers }
        );

        const response = await axios.get(
          `${config.api.invokeUrl}/GetSignedUrl/${product.id}`,
          { headers }
        );

        const presignedUrl = response.data.url;
        window.location.href = presignedUrl;
      } else {
        console.error("Email is undefined.");
      }
    } catch (error) {
      console.error("Error during the download process:", error);
    }
  };

  setIsEditMode = (value) => {
    this.setState({ isEditMode: value });
  };

  setShowMainContent = (value) => {
    this.setState({ showMainContent: value });
  };

  setCurrentView = (value) => {
    this.setState({ currentView: value });
  };

  handleAdminClick = () => {
    this.props.history.push("/admin");
  };

  handleEditClick = (id) => {
    if (!this.props || !id) {
      console.error("Missing props or id");
      return;
    }

    const productToEdit = this.state.products.find(
      (product) => product.id === id
    );
    if (!productToEdit) {
      console.error("Product not found for id:", id);
      return;
    }
    const { version, fullname, descriptionothers, md5hash } = productToEdit;
    
    this.setState((prevState) => ({
      editedProducts: {
        ...prevState.editedProducts,
        [id]: {
          version,
          fullname,
          descriptionothers,
          md5hash,
        },
      },
    }));

    this.setState({
      editingProductId: id,
      isEditMode: true,
    });
  };

  handleSaveClick = async (id) => {
    const auth = new Authentication();
    try {
      const updatedProduct = {
        id: id,
        version: this.state.editedProducts[id].version || ' ',
        fullname: this.state.editedProducts[id].fullname || ' ',
        descriptionothers: this.state.editedProducts[id].descriptionothers || ' ',
        md5hash: this.state.editedProducts[id].md5hash || ' ',
        download: this.state.editedProducts[id].download || ' ',
      };

      await auth.login();
      const idToken = sessionStorage.getItem("idToken");
      const headers = { Authorization: `Bearer ${idToken}` };

      await axios.patch(
        `${config.api.invokeUrl}/Products/${id}`,
        updatedProduct,
        { headers }
      );

      this.setState(prevState => ({
        products: prevState.products.map(product => {
          if (product.id === id) {
            return { ...product, ...updatedProduct };
          }
          return product;
        }),
        isEditMode: false,
        editedProducts: {},
      }));
    } catch (err) {
      console.error(`Error updating product: ${err}`);
      window.alert(`Error updating product: ${err}`);
    }
  };

  handleCancelClick = () => {
    this.setState({
      isEditMode: false,
      editedProducts: {},
    });
  };

  handleDeleteProduct = async (id, event) => {
    event.preventDefault();

    const confirmDelete = window.confirm(
      "Are you sure you want to delete this product?"
    );

    if (confirmDelete) {
      const auth = new Authentication();
      try {
        await auth.login();
        const idToken = sessionStorage.getItem("idToken");

        const headers = {
          Authorization: `Bearer ${idToken}`,
        };
        await axios.delete(`${config.api.invokeUrl}/Products/${encodeURIComponent(id)}`, {
          headers,
        });
        const updatedProducts = this.state.products.filter(
          (product) => product.id !== id
        );
        this.setState({
          products: updatedProducts,
        });
        alert(`Product with ID ${id} deleted.`);
      } catch (err) {
        console.log(`Unable to delete product: ${err}`);
      }
    }
  };

  handleBackClick = () => {
    const { currentView } = this.state;

    const componentKey = currentView.replace("Others", "");

    const stateKey = `show${componentKey}Content`;

    this.setState(
      {
        [stateKey]: false, 
        currentView: "main",
      },
      () => {
        this.setShowMainContent(true);
      }
    );
  };

  Otherscustomx = () => {
    return (
      <div className="others-container">
        <div className="others-year-logo-container">
          <img
            className="logo-customx"
            alt="Logo customx"
            src={require("../img/customx-Logo.png")}
            style={{
              transform: "translate(-50%)",
              position: "relative",
              left: "130px",
              height: "auto",
            }}
          />
        </div>
        <div className="others-year-container">
          {this.OthersYear("customx")}
        </div>
      </div>
    );
  };
  Othersexs = () => { 
    return (
      <div className="others-container">
        <div className="others-year-logo-container">
          <img
            className="logo-exs"
            alt="Logo exs"
            src={require("../img/exs-Logo.png")}
            style={{
              transform: "translate(-50%)",
              position: "relative",
              left: "65px",
              height: "auto",
            }}
          />
        </div>
        <div className="others-year-container">
          {this.OthersYear("exs")}
        </div>
      </div>
    );
  };
  Othersginfo = () => { 
    return (
      <div className="others-container">
        <div className="others-year-logo-container">
          <img
            className="logo-ginfo"
            alt="Logo ginfo"
            src={require("../img/ginfo-Logo.png")}
            style={{
              transform: "translate(-50%)",
              position: "relative",
              left: "172px",
              height: "auto",
            }}
          />
        </div>
        <div className="others-year-container">
          {this.OthersYear("ginfo")}
        </div>
      </div>
    );
  };
  Othersmumbimbooster = () => {
    return (
      <div className="others-container">
        <div className="others-year-logo-container">
          <img
            className="logo-mumbimbooster"
            alt="Logo bim booster"
            src={require("../img/mumbimbooster-Logo.png")}
            style={{
              transform: "translate(-50%)",
              position: "relative",
              left: "172px",
              height: "auto",
            }}
          />
        </div>
        <div className="others-year-container">
          {this.OthersYear("mumbimbooster")}
        </div>
      </div>
    );
  };
  Othersmumsteelworkforinventor = () => {
    return (
      <div className="others-container">
        <div className="others-year-logo-container">
          <img
            className="logo-mumsteelworkforinventor"
            alt="Logo steel work"
            src={require("../img/mumsteelworkforinventor-Logo.png")}
            style={{
              transform: "translate(-50%)",
              position: "relative",
              left: "148px",
              height: "auto",
            }}
          />
        </div>
        <div className="others-year-container">
          {this.OthersYear("mumsteelworkforinventor")}
        </div>
      </div>
    );
  };

  Othersmapedit = () => {
    return (
      <div className="others-container">
        <div className="others-year-logo-container">
          <img
            className="logo-mapedit"
            alt="Logo map edit"
            src={require("../img/mapedit-Logo.png")}
            style={{
              transform: "translate(-50%)",
              position: "relative",
              left: "130px",
              height: "auto",
            }}
          />
        </div>
        <div className="others-year-container">
          {this.OthersYear("mapedit")}
        </div>
      </div>
    );
  };
  Othersmumpdmbooster = () => {
    return (
      <div className="others-container">
        <div className="others-year-logo-container">
          <img
            className="logo-mumpdmbooster"
            alt="Logo pdm booster"
            src={require("../img/mumpdmbooster-Logo.png")}
            style={{
              transform: "translate(-50%)",
              position: "relative",
              left: "175px",
              height: "auto",
            }}
          />
        </div>
        <div className="others-year-container">
          {this.OthersYear("mumpdmbooster")}
        </div>
      </div>
    );
  };
  Othersmumqtobooster = () => {
    return (
      <div className="others-container">
        <div className="others-year-logo-container">
          <img
            className="logo-mumqtobooster"
            alt="Logo qto booster"
            src={require("../img/mumqtobooster-Logo.png")}
            style={{
              transform: "translate(-50%)",
              position: "relative",
              left: "155px",
              height: "auto",
            }}
          />
        </div>
        <div className="others-year-container">
          {this.OthersYear("mumqtobooster")}
        </div>
      </div>
    );
  };
  Othersmummultitoolforinventor = () => {
    return (
      <div className="others-container">
        <div className="others-year-logo-container">
          <img
            className="logo-mummultitoolforinventor"
            alt="Logo multitool for inventor"
            src={require("../img/mummultitoolforinventor-Logo.png")}
            style={{
              transform: "translate(-50%)",
              position: "relative",
              left: "195px",
              height: "auto",
            }}
          />
        </div>
        <div className="others-year-container">
          {this.OthersYear("mummultitoolforinventor")}
        </div>
      </div>
    );
  };
  Othersmummaterialbrowserforinventor = () => {
    return (
      <div className="others-container">
        <div className="others-year-logo-container">
          <img
            className="logo-mummaterialbrowserforinventor"
            alt="Logo material browser for inventor"
            src={require("../img/mummaterialbrowserforinventor-Logo.png")}
            style={{
              transform: "translate(-50%)",
              position: "relative",
              left: "195px",
              height: "auto",
            }}
          />
        </div>
        <div className="others-year-container">
          {this.OthersYear("mummaterialbrowserforinventor")}
        </div>
      </div>
    );
  };

  Othersmumpraxispaketstahlbau = () => {
    return (
      <div className="others-container">
        <div className="others-year-logo-container">
          <img
            className="logo-mumpraxispaketstahlbau"
            alt="Logo praxispaket stahlbau"
            src={require("../img/mumpraxispaketstahlbau-Logo.png")}
            style={{
              transform: "scale(1) translate(-50%)",
              position: "relative",
              left: "230px",
            }}
          />
        </div>
        <div className="others-year-container">
          {this.OthersYear("mumpraxispaketstahlbau")}
        </div>
      </div>
    );
  };
  Othersmumautocadbooster = () => {
    return (
      <div className="others-container">
        <div className="others-year-logo-container">
          <img
            className="logo-mumautocadbooster"
            alt="Logo Autocad Toolbox"
            src={require("../img/mumautocadbooster-Logo.png")}
            style={{
              transform: "translate(-50%)",
              position: "relative",
              left: "185px",
              top: "0",
            }}
          />
        </div>
        <div className="others-year-container">
          {this.OthersYear("mumautocadbooster")}
        </div>
      </div>
    );
  };

  setSelectedYearForMumqtobooster = (year) => {
    this.setState({ selectedYear: year });
  };
  
  setStateForComponent = (component, value) => {
    const stateKey = `show${component}Content`;
  
    if (value === false) {
      this.setState({ [stateKey]: false }, () => {
      });
    } else {
      this.setState({ [stateKey]: value }, () => {
        if (component === "Othersmumqtobooster") {
          this.setSelectedYearForMumqtobooster("V8");
        }
      });
    }
  };

  handleOthersClick = (component) => {
    if (!this.props.auth.isAuthenticated) {
      window.location.href = config.LOGIN_URL;
      return;
    }
    this.setShowMainContent(false);

    this.setStateForComponent(component, true);
    this.setCurrentView(component);
  };

  handleYearButtonClick(year) {
    this.setSelectedYear(year);
    this.setState({ selectedYear: year }, () => {
    });
    this.setSelectedCategory("Release");
  }

  handleCategoryClick(category) {
    this.setSelectedCategory(category);
  }

  onInputChange = (event, productId, field) => {
    const { value } = event.target;

    this.setState((prevState) => ({
      editedProducts: {
        ...prevState.editedProducts,
        [productId]: {
          ...prevState.editedProducts[productId],
          [field]: value,
        },
      },
    }));
  };

  OthersYear(software) {
    const { selectedYear, selectedCategory } = this.state;
    const softwareProducts = this.state.products.filter(
      (product) => product.software === software
    );
    const userLicenses = this.props.auth.userLicenses || [];

    const formatDate = (date) => {
      const day = date.getDate().toString().padStart(2, "0");
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const year = date.getFullYear();
      return `${day}.${month}.${year}`;
    };

    const formatFileSize = (bytes) => {
      if (isNaN(bytes) || bytes < 0) {
        return "N/A";
      }

      const kilobyte = 1024;
      const megabyte = kilobyte * 1024;
      const gigabyte = megabyte * 1024;

      if (bytes < kilobyte) {
        return bytes + " B";
      } else if (bytes < megabyte) {
        return (bytes / kilobyte).toFixed(2) + " KB";
      } else if (bytes < gigabyte) {
        return (bytes / megabyte).toFixed(2) + " MB";
      } else {
        return (bytes / gigabyte).toFixed(2) + " GB";
      }
    };

    const fieldOrder = ["fullname", "descriptionothers", "version", "md5hash"];

      const renderYearButtons = () => {
          const generalAccess = userLicenses.includes("Download Portal General Access");
          const hasQTOBoosterLicense = userLicenses && (
            userLicenses.includes("QTO Booster 8") ||
            userLicenses.includes("QTO Booster 7"));
          if (software === "mumqtobooster" && (generalAccess || hasQTOBoosterLicense)) {
              return (
                <>
                  <div className="others-year-container">
                    {(generalAccess || userLicenses.includes("QTO Booster 8")) && (
                      <button
                        className={`others-year-button ${selectedYear === "V8" && "selected"}`}
                        onClick={() => this.handleYearButtonClick("V8")}
                      >
                        V8
                      </button>
                    )}
                    {(generalAccess || userLicenses.includes("QTO Booster 7")) && (
                      <button
                        className={`others-year-button ${selectedYear === "V7" && "selected"}`}
                        onClick={() => this.handleYearButtonClick("V7")}
                      >
                        V7
                        </button>
                    )}
                  </div>
                  <div className="seperator"></div>
                </>
              );
        } else if (software === "mapedit") {
          return null;
        } else {
          const filteredYears = software === "mumbimbooster"
            ? [2025, 2024, 2023]
            : software === "mumautocadbooster"
            ? [2025]
            : this.years;
          return (
            <>
              <div className="others-year-container">
                {filteredYears.map((year) => (
                  <button
                    key={year}
                    className={`others-year-button ${selectedYear === year && "selected"}`}
                    onClick={() => this.handleYearButtonClick(year)}
                  >
                    {year}
                  </button>
                ))}
              </div>
              <div className="seperator"></div>
            </>
          );
        }
      };

      const renderCategoryFilters = () => {
        const renderCategoryDescription = (category, software, year) => {
          if (selectedCategory === category) {
              const year_category = `${year}_${category}`;
              const description = this.state.categoryDescriptions?.[software]?.[year_category] || "";      
              return (
                <div className="category-description-container">
                    {this.isAdmin() ? (
                        <>
                            <ReactQuill
                                value={description}
                                onChange={(content) => this.handleCategoryDescriptionChange({ target: { value: content } }, category, software, year)}
                                className="category-description-textarea"
                                theme="snow"
                            />
                            <button
                                onClick={() => this.handleCategoryDescriptionSave(category, software, year)}
                                className="save-description-button"
                            >
                                Save
                            </button>
                        </>
                    ) : (
                        <div
                            className="category-description-text"
                            dangerouslySetInnerHTML={{ __html: description }}
                        />
                    )}
                </div>
            );
          }
          return null;
        };
        if (software === "mumqtobooster") {
          const categoryMapping = {
            Release: "Release notes - German",
            Updates: "Release notes - English",
            Documentation: "Documentation Installation",
          };
            return (
                <div className="category-wrapper">
                    <div className="others-category-container">
                        {this.categories.filter((category) => category !== "Module").map((category) => (
                            <div key={category} className="category-container">
                                <button
                                    className={`others-year-category-button ${
                                        selectedYear === "V8" ? "visible" : ""
                                    } ${selectedCategory === category ? "selected" : ""}`}
                                    onClick={() => this.handleCategoryClick(category)}
                                >
                                    {categoryMapping[category] || category}
                                </button>
                            </div>
                        ))}
                    </div>
                    {renderCategoryDescription(selectedCategory, software, selectedYear)}
                </div>
            );
        } else if (software === "mumbimbooster" /* && this.state.showContentCategory */) {
          const categoryMapping = {
            Release: "Release notes - German",
            Updates: "Release notes - English",
            Module: "App collision - Free",
            Documentation: "Documentation Installation",
          };
            return (
                <div className="category-wrapper">
                    <div className="others-category-container">
                        {this.categories.map((category) => (
                            <div key={category} className="category-container">
                                <button
                                    className={`others-year-category-button ${
                                        selectedYear === 2025 ? "visible" : ""
                                    } ${selectedCategory === category ? "selected" : ""}`}
                                    onClick={() => this.handleCategoryClick(category)}
                                >
                                    {categoryMapping[category] || category}
                                </button>
                            </div>
                        ))}
                        {/* <div className="category-container">
                            <button
                                key="Content"
                                className={`others-year-category-button ${selectedCategory === "Content" ? "selected" : ""}`}
                                onClick={() => this.handleCategoryClick("Content")}
                            >
                                Content
                            </button>
                        </div> */}
                    </div>
                    {renderCategoryDescription(selectedCategory === "Content" ? "Content" : selectedCategory, software, selectedYear)}
                </div>
            );
        } else if (software === "mumautocadbooster") {
          const allowedCategories = ["Release", "Documentation"];
          return (
              <div className="category-wrapper">
                  <div className="others-category-container">
                      {allowedCategories.map((category) => (
                          <div key={category} className="category-container">
                              <button
                                  className={`others-year-category-button ${
                                      selectedYear === 2025 ? "visible" : ""
                                  } ${selectedCategory === category ? "selected" : ""}`}
                                  onClick={() => this.handleCategoryClick(category)}
                              >
                                  {category}
                              </button>
                          </div>
                      ))}
                  </div>
                  {renderCategoryDescription(selectedCategory, software, selectedYear)}
              </div>
          );
        } else {
            return (
                <div className="category-wrapper">
                    <div className="others-category-container">
                        {this.categories.map((category) => (
                            <div key={category} className="category-container">
                                <button
                                    className={`others-year-category-button ${
                                        selectedYear === 2025 ? "visible" : ""
                                    } ${selectedCategory === category ? "selected" : ""}`}
                                    onClick={() => this.handleCategoryClick(category)}
                                >
                                    {category}
                                </button>
                            </div>
                        ))}
                    </div>
                    {renderCategoryDescription(selectedCategory, software, selectedYear)}
                </div>
            );
        }
    };

    const renderProductTable = () => {
      if (software === "mapedit") {
        const filteredProducts = softwareProducts.filter((product) => {
          return product.category === selectedCategory;
        });

        const renderTableBody = () => {
          return filteredProducts.map((product, rowIndex) => (
            <tr style={{ textAlign: "center" }} key={rowIndex}>
              {this.isAdmin() && this.state.isEditMode ? (
                fieldOrder.map((field, index) => (
                  <td key={index}>
                    {this.state.isEditMode ? (
                      <input
                        type="text"
                        value={
                          this.state.editedProducts[product.id]?.[`${field}`] ||
                          ""
                        }
                        onChange={(event) =>
                          this.onInputChange(event, product.id, `${field}`)
                        }
                      />
                    ) : (
                      product[field]
                    )}
                  </td>
                ))
              ) : (
                <>
                  <td style={{ textAlign: "left" }}>{product.fullname}</td>
                  {this.isAdmin() && <td>{product.descriptionothers}</td>}
                  {this.isAdmin() && <td>{product.version}</td>}
                  <td>
                    {product.uploadDate
                      ? formatDate(new Date(product.uploadDate))
                      : "N/A"}
                  </td>
                  <td>{formatFileSize(product.fileSize)}</td>
                  {this.isAdmin() && <td>{product.md5hash}</td>}
                </>
              )}
              {this.isAdmin() ? (
                <>
                  <td>
                    {this.state.isEditMode &&
                    this.state.editingProductId === product.id ? (
                      <>
                        <button onClick={() => this.handleSaveClick(product.id)}>
                          Save&nbsp;&nbsp;
                        </button>
                        <button onClick={() => this.handleCancelClick()}>
                          Cancel
                        </button>
                      </>
                    ) : (
                      <img
                        onClick={() => this.handleEditClick(product.id)}
                        src={require("../img/edit.png")}
                        alt="Edit"
                        style={{ width: "20px", height: "20px" }}
                      />
                    )}
                  </td>
                  <td>
                    <img
                      onClick={(event) =>
                        this.handleDeleteProduct(product.id, event)
                      }
                      src={require("../img/delete.png")}
                      alt="Delete"
                      style={{ width: "20px", height: "20px" }}
                    />
                  </td>
                </>
              ) : null}
              <td>
                <button
                  className="others-table-download"
                  onClick={() =>
                    this.handleDownloadtableClick(
                      product,
                      null,
                      selectedCategory
                    )
                  }
                >
                  DOWNLOAD
                </button>
              </td>
              <td>
                <button
                  className="others-table-releasenotes"
                  onClick={() =>
                    this.handleReleaseNotesClick(product.releaseNotes, product.id)
                  }
                >
                  Release Notes
                </button>
              </td>
            </tr>
          ));
        };

        return (
          <div key={software}>
            <table className="category-table">
              <thead>
              <tr style={{ textAlign: "center" }}>
                <th className="sortable-column align-left" onClick={() => this.handleSort('name')}>
                  <div className="grid-container">
                    <span>Name</span>
                    {this.state.sortOrder.column === 'name' && (
                      <span className="sort-arrows">{this.state.sortOrder.direction === 'asc' ? '▲' : '▼'}</span>
                    )}
                  </div>
                </th>
                {this.isAdmin() && <th className="centered"><div className="grid-container">Description</div></th>}
                {this.isAdmin() && <th className="centered"><div className="grid-container">Version</div></th>}
                {!this.state.isEditMode && (
                  <th className="sortable-column centered" onClick={() => this.handleSort('date')}>
                    <div className="grid-container centered-grid">
                      <span>Date</span>
                      {this.state.sortOrder.column === 'date' && (
                        <span className="sort-arrows">{this.state.sortOrder.direction === 'asc' ? '▲' : '▼'}</span>
                      )}
                    </div>
                  </th>
                )}
                {!this.state.isEditMode && <th className="centered"><div className="grid-container">File size</div></th>}
                {this.isAdmin() && <th className="centered"><div className="grid-container">Md5Hash</div></th>}
                {this.isAdmin() && (
                  <>
                    <th className="centered"><div className="grid-container">Edit</div></th>
                    <th className="centered"><div className="grid-container">Delete</div></th>
                  </>
                )}
                <th className="centered"><div className="grid-container">Download</div></th>
                <th className="centered"><div className="grid-container">Release Notes</div></th>
              </tr>
              </thead>
              <tbody>
                {filteredProducts.length > 0 ? (
                  renderTableBody()
                ) : (
                  <tr>
                    <td colSpan={this.isAdmin() ? 10 : 7} style={{ textAlign: "center", padding: "10px" }}>
                      No products available for download
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        );
      } else {

      const filteredProducts = softwareProducts.filter((product) => {
        if (software === "mumqtobooster" && (selectedYear === "V8" || selectedYear === "V7")) {
          return product.year === selectedYear && product.category === selectedCategory;
        } else if (software !== "mumqtobooster") {
          const productYear = isNaN(product.year) ? product.year : parseInt(product.year, 10);
          const selectedYearParsed = isNaN(selectedYear) ? selectedYear : parseInt(selectedYear, 10);
          return productYear === selectedYearParsed && product.category === selectedCategory;
        }
        return false;
      });

      const renderTableBody = () => {
        return filteredProducts.map((product, rowIndex) => (
          <tr style={{ textAlign: "center" }} key={rowIndex}>
            {this.isAdmin() && this.state.isEditMode ? (
              fieldOrder.map((field, index) => (
                <td key={index}>
                  {this.state.isEditMode ? (
                    <input
                      type="text"
                      value={
                        this.state.editedProducts[product.id]?.[`${field}`] ||
                        ""
                      }
                      onChange={(event) =>
                        this.onInputChange(event, product.id, `${field}`)
                      }
                    />
                  ) : (
                    product[field]
                  )}
                </td>
              ))
            ) : (
              <>
                <td style={{ textAlign: "left" }}>{product.fullname}</td>
                {this.isAdmin() && <td>{product.descriptionothers}</td>}
                {this.isAdmin() && <td>{product.version}</td>}
                <td>
                  {product.uploadDate
                    ? formatDate(new Date(product.uploadDate))
                    : "N/A"}
                </td>
                <td>{formatFileSize(product.fileSize)}</td>
                {this.isAdmin() && <td>{product.md5hash}</td>}
              </>
            )}
            {this.isAdmin() ? (
              <>
                <td>
                  {this.state.isEditMode &&
                  this.state.editingProductId === product.id ? (
                    <>
                      <button onClick={() => this.handleSaveClick(product.id)}>
                        Save&nbsp;&nbsp;
                      </button>
                      <button onClick={() => this.handleCancelClick()}>
                        Cancel
                      </button>
                    </>
                  ) : (
                    <img
                      onClick={() => this.handleEditClick(product.id)}
                      src={require("../img/edit.png")}
                      alt="Edit"
                      style={{ width: "20px", height: "20px" }}
                    />
                  )}
                </td>
                <td>
                  <img
                    onClick={(event) =>
                      this.handleDeleteProduct(product.id, event)
                    }
                    src={require("../img/delete.png")}
                    alt="Delete"
                    style={{ width: "20px", height: "20px" }}
                  />
                </td>
              </>
            ) : null}
            <td>
              <button
                className="others-table-download"
                onClick={() =>
                  this.handleDownloadtableClick(
                    product,
                    selectedYear,
                    selectedCategory
                  )
                }
              >
                DOWNLOAD
              </button>
            </td>
            {(software === "mumbimbooster" || software === "mumqtobooster") ? null : (
            <td>
              <button
                className="others-table-releasenotes"
                onClick={() =>
                  this.handleReleaseNotesClick(product.releaseNotes, product.id)
                }
              >
                Release Notes
              </button>
            </td>
            )}
          </tr>
        ));
      };

      return (
        <div key={software}>
          <table className="category-table">
            <thead>
            <tr style={{ textAlign: "center" }}>
              <th className="sortable-column align-left" onClick={() => this.handleSort('name')}>
                <div className="grid-container">
                  <span>Name</span>
                  {this.state.sortOrder.column === 'name' && (
                    <span className="sort-arrows">{this.state.sortOrder.direction === 'asc' ? '▲' : '▼'}</span>
                  )}
                </div>
              </th>
              {this.isAdmin() && <th className="centered"><div className="grid-container">Description</div></th>}
              {this.isAdmin() && <th className="centered"><div className="grid-container">Version</div></th>}
              {!this.state.isEditMode && (
                <th className="sortable-column centered" onClick={() => this.handleSort('date')}>
                  <div className="grid-container centered-grid">
                    <span>Date</span>
                    {this.state.sortOrder.column === 'date' && (
                      <span className="sort-arrows">{this.state.sortOrder.direction === 'asc' ? '▲' : '▼'}</span>
                    )}
                  </div>
                </th>
              )}
              {!this.state.isEditMode && <th className="centered"><div className="grid-container">File size</div></th>}
              {this.isAdmin() && <th className="centered"><div className="grid-container">Md5Hash</div></th>}
              {this.isAdmin() && (
                <>
                  <th className="centered"><div className="grid-container">Edit</div></th>
                  <th className="centered"><div className="grid-container">Delete</div></th>
                </>
              )}
              <th className="centered"><div className="grid-container">Download</div></th>
              <th className="centered">
                <div className="grid-container">
                  {(software === "mumbimbooster" || software === "mumqtobooster") ? null : "Release Notes"}
                </div>
              </th>
            </tr>
            </thead>
            <tbody>
              {filteredProducts.length > 0 ? (
                renderTableBody()
              ) : (
                <tr>
                  <td colSpan={this.isAdmin() ? 10 : 7} style={{ textAlign: "center", padding: "10px" }}>
                    No products available for download
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          {this.renderReleaseNotesPopup()}
        </div>
      );
    }
    };
    return (
      <div className="others-container">
        {renderYearButtons()}
        {(software === "mumbimbooster" || software === "mumqtobooster") ? (
          <>
            {selectedCategory !== "Updates" && renderProductTable()}
            {selectedYear && renderCategoryFilters()}
          </>
        ) : (
          <>
            {selectedYear && renderCategoryFilters()}
            {renderProductTable()}
          </>
        )}
      </div>
    );
  }

  getTitleAndDescription(software) {
    const { selectedPageLanguage } = this.state;
    const softwareInfo = {
      customx: {
        Deutsch: {
          title: "customX",
          description:
            "customX ermöglicht die Konfiguration komplexer Variantenprodukte mit der Option zur Veröffentlichung im Web. Sämtliche Dokumente, wie Stücklisten oder maßstäbliche Fertigungszeichnungen, werden automatisiert erzeugt.",
        },
        English: {
          title: "customX",
          description: "customX enables the configuration of complex variant products with the option of publishing online. All documents, such as parts lists or scaled production drawings, are generated automatically.",
        },
      },
      exs: {
        Deutsch: {
          title: "eXs",
          description:
            "eXs ist ein echtes mechatronisches System. Ob klassische Elektrodokumentation, Hydraulik und Pneumatik, Gebäude- oder Verfahrenstechnik – eXs hat für jede Sparte die richtigen Symbole und Möglichkeiten für die entsprechenden Planarten und unterstützt die jeweils aktuellen Normen.",
        },
        English: {
          title: "eXs",
          description: "eXs is a genuine mechatronic system. Whether classic electrical documentation, hydraulics and pneumatics, building or process engineering - eXs has the right symbols and options for the corresponding plan types for each sector and supports the respective current standards.",
        },
      },
      ginfo: {
        Deutsch: {
          title: "G-Info NET Server",
          description:
            "Anwendungsserver für beliebige Webprojekte und G-Info-Projekte.",
        },
        English: {
          title: "G-Info NET Server",
          description: "Application server for any web project and G-Info projects.",
        },
      },
      mapedit: {
        Deutsch: {
          title: "MapEdit",
          description:
            "Laden Sie hier die aktuellen Versionen und Release der MapEdit Produkte im Rahmen Ihres Software-Service-Vertrages herunter.",
        },
        English: {
          title: "MapEdit",
          description: "Download the latest versions and releases of MapEdit products as part of your software service contract.",
        },
      },
      mumautocadbooster: {
        Deutsch: {
          title: "ACAD Toolbox",
          description:
            "Die ACAD Toolbox ist der Turbo für AutoCAD und macht dank effizienzsteigernder Werkzeuge und optimierter Benutzeroberfläche Nutzer effektiver und effizienter in ihrer täglichen Arbeit.",
        },
        English: {
          title: "ACAD Toolbox",
          description: "The ACAD Toolbox is the turbo for AutoCAD and makes users more effective and efficient in their daily work thanks to efficiency-enhancing tools and an optimized user interface.",
        },
      },
      mumbimbooster: {
        Deutsch: {
          title: "BIM Booster",
          description:
            "Der BIM Booster wurde entwickelt, um die tägliche Arbeit mit Autodesk Revit in einem hohen Maß an Effizienz und Wirtschaftlichkeit zu versehen. Alle Module des BIM Booster sind vollständig in Autodesk Revit integriert.",
        },
        English: {
          title: "BIM Booster",
          description: "The BIM Booster was developed to enhance your daily work with Autodesk Revit by driving even higher levels of productivity and efficiency. All BIM Booster modules are fully integrated into Autodesk Revit.",
        },
      },
      mummaterialbrowserforinventor: {
        Deutsch: {
          title: "MuM Material Browser",
          description:
            "Mit normierten Werkstoffdaten sorgt der MuM Material Browser für genauere Simulationen und spart Zeit, indem er die manuelle Eingabe von Materialdaten überflüssig macht. Die Normierung gewährleistet zudem eine einheitliche Darstellung der Materialien in Dokumentationen.",
        },
        English: {
          title: "MuM Material Browser",
          description: "With standardized material data, the MuM Material Browser ensures more accurate simulations and saves time by eliminating the need to enter material data manually. Standardization also ensures a uniform presentation of materials in documentation.",
        },
      },
      mumpdmbooster: {
        Deutsch: {
          title: "PDM Booster",
          description:
            "Mit dem MuM PDM Booster erweitern Sie Ihr Datenmanagement um eine Vielzahl praxisorientierter Funktionen. Profitieren Sie von einer einfachen Bedieneroberfläche sowie der Möglichkeit, Ihre Daten nach Ihren Firmenstandards zu strukturieren. Stücklisten werden erzeugt, geprüft - das ERP-System wird angebunden und PLM integriert.",
        },
        English: {
          title: "PDM Booster",
          description: "With the MuM PDM Booster, you can expand your data management with a variety of practical functions. Benefit from a simple user interface and the option of structuring your data according to your company standards. Parts lists are generated and checked – the ERP system is connected and PLM is integrated.",
        },
      },
      mumqtobooster: {
        Deutsch: {
          title: "QTO Booster",
          description:
            "Der MuM QTO Booster unterstützt Architekten, Planer und Ausführende schon nach kürzester Einarbeitungszeit bei der Mengen- und Kostenermittlung aus 2D-Bild- und -Vektorplänen. Und das nach gewohnten Messroutinen und ohne CAD-Vorkenntnisse.",
        },
        English: {
          title: "QTO Booster",
          description: "The MuM QTO Booster supports architects, planners and contractors in determining quantities and costs from 2D image and vector plans after just a short training period. And all this by using familiar measurement routines and without prior CAD knowledge.",
        },
      },
      mummultitoolforinventor: {
        Deutsch: {
          title: "MuM Multitool",
          description:
            "MuM Multitool für Inventor unterstützt Sie bei den zeitraubenden Tätigkeiten in Ihrem Konstruktionsalltag. Dank Funktionen wie dem Drucken aller IDW-Dateien, dem Konsolidieren und Anzeigen von technischen iProperties oder Sammelangaben für Oberflächenzeichen steigern Sie Ihre Effizienz bei der täglichen Arbeit und gewinnen Zeit für die wichtigen Dinge.",
        },
        English: {
          title: "MuM Multitool",
          description: "MuM Multitool for Inventor supports you in the time-consuming tasks of your daily construction routine. Thanks to functions such as printing all IDW files, d consolidating and displaying technical iProperties or collective information for surface symbols, your efficiency in daily work increases and you gain time for important things.",
        },
      },
      mumsteelworkforinventor: {
        Deutsch: {
          title: "Steelwork",
          description:
            "Mit MuM Steelwork kann der Konstrukteur z. B. den Zugang zu seiner Maschine oder auch Tragwerke, Bedien- und Wartungsbühnen, Treppen und Geländer innerhalb von Inventor konstruieren. Stahlkonstruktion werden dabei in der gleichen Baugruppenstruktur erzeugt und bearbeitet. Neben der einheitlichen Zeichnungs- und Stücklistenerstellung, der durchgehenden Datenverwaltung, bedeutet dies erhebliche Vorteile beim nachträglichen Ändern.",
        },
        English: {
          title: "Steelwork",
          description: "With MuM Steelwork the designer can create e.g. supporting structures, substructures, operating and maintenance platforms, stairs and railings within Inventor. Mechanical and steel designs are created and processed in the same assembly structure. In addition to the uniform creation of drawings and parts lists and the continuous data management, this means considerable advantages when making subsequent changes.",
        },
      },
      mumpraxispaketstahlbau: {
        Deutsch: {
          title: "Praxispaket Stahlbau",
          description:
            "Das Praxispaket Stahlbau erweitert die Arbeitsprozesse des Advance Steel Anwenders mit neuen Funktionen wie z.B. den Volumenkörper-Export, die Massenänderung und den Stücklisten-Export. Dabei werden Aufgaben, die mit erheblichem Aufwand verbunden sind, durch die Anwendung des Advance Steel Praxispaket beschleunigt.",
        },
        English: {
          title: "Praxispaket Stahlbau",
          description: "The MuM steel construction booster extends the Advance Steel user's work processes with new functions such as the export of solids, multi edit and the export of parts lists. Tasks that involve considerable effort are accelerated by using the Advance Steel Booster.",
        },
      },
    };
    return softwareInfo[software][selectedPageLanguage];
  }

  checkProductAccess = (software) => {
    const userLicenses = this.props.auth.userLicenses || [];
    const hasGeneralAccess = userLicenses.includes("Download Portal General Access");
    
    const licenseMap = {
      "customx": userLicenses.includes("Custom X"),
      "exs": userLicenses.includes("eXs"),
      "ginfo": userLicenses.includes("G-Info"),
      "mapedit": userLicenses.includes("Map Edit"),
      "mumautocadbooster": userLicenses.includes("ACAD Toolbox"),
      "mumbimbooster": userLicenses.includes("Bim Booster"),
      "mummaterialbrowserforinventor": userLicenses.includes("Material Browser"),
      "mummultitoolforinventor": userLicenses.includes("Multitool"),
      "mumpdmbooster": userLicenses.includes("PDM Booster"),
      "mumpraxispaketstahlbau": userLicenses.includes("Praxispaket Stahlbau"),
      "mumqtobooster": userLicenses.some(license => 
         ["QTO Booster 7", "QTO Booster 8"].includes(license)),
      "mumsteelworkforinventor": userLicenses.includes("Steelwork")
    };

    return hasGeneralAccess || licenseMap[software];
  };
  renderQTOBoosterSelects = (productId, selectedValues) => {
    const userLicenses = this.props.auth.userLicenses;
    const hasGeneralAccess = userLicenses.includes("Download Portal General Access");

    return (
      <>
        <select
          className="year"
          value={selectedValues.version || ""}
          onChange={(e) => this.setSelectedValue("version", e.target.value, productId)}
        >
          {(hasGeneralAccess || userLicenses.includes("QTO Booster 8")) && (
            <option value="V8">V8</option>
          )}
          {(hasGeneralAccess || userLicenses.includes("QTO Booster 7")) && (
            <option value="V7">V7</option>
          )}
        </select>
        <select
          className="language"
          value={selectedValues.language || ""}
          onChange={(e) => this.setSelectedLanguage(e.target.value, productId)}
        >
          {this.languages.map((language) => (
            <option key={language} value={language}>
              {language}
            </option>
          ))}
        </select>
      </>
    );
  };

  renderStandardSelects = (productId, selectedValues) => {
    const years =
      selectedValues.software === "mumbimbooster"
        ? this.filteredYears.mumbimbooster
        : this.years;

    const selectedYear = years.includes(selectedValues.year)
      ? selectedValues.year
      : years[0];
    return (
      <>
        <select
          className="year"
          value={selectedYear || ""}
          onChange={(e) => this.setSelectedYear(Number(e.target.value), productId)}
        >
          {years.map((year) => (
            <option key={year} value={year}>
              {year}
            </option>
          ))}
        </select>
        <select
          className="language"
          value={selectedValues.language || ""}
          onChange={(e) => this.setSelectedLanguage(e.target.value, productId)}
        >
          {this.languages.map((language) => (
            <option key={language} value={language}>
              {language}
            </option>
          ))}
        </select>
      </>
    );
  };
  /*---------------------------------------------------------*/

  render() {
    const { showMainContent, currentView, selectedPageLanguage } = this.state;
    const isOthersView = currentView.startsWith("Others");
    const sortedProducts = Object.values(
      this.state.products.reduce((acc, product) => {
        const hasDuplicateSoftware = acc[product.software];

        if (!hasDuplicateSoftware) {
          acc[product.software] = product;
        }

        return acc;
      }, {})
    ).sort((a, b) => {
      const titleA = this.getTitleAndDescription(a.software)?.title || "";
      const titleB = this.getTitleAndDescription(b.software)?.title || "";
      return titleA.localeCompare(titleB);
    });
    return (
      <div className="window">
        <div className="before-frame">
          <div className="imagecover">
            <img
              className="mumservices-image"
              alt=""
              src={require('../img/image-cloud-services2.jpg')}
            />
          </div>
          <div className="pagelanguage">
            <select
              className="changelanguage"
              value={selectedPageLanguage}
              onChange={this.handlePageLanguageChange}
            >
              {this.state.pagelanguages.map((pagelanguage, index) => (
                <option key={index} value={pagelanguage}>
                  {pagelanguage}
                </option>
              ))}
            </select>
          </div>
          <div className="button-container">
            <button className="button-anmelden" onClick={this.handleLoginClick}>
              <i className="icon-login"></i>&nbsp;&nbsp;Login
            </button>
            {this.props.auth.isAuthenticated && (
              <button
                href={config.LOGIN_URL}
                onClick={this.handleLogoutClick}
                className="button-anmelden"
              >
                Logout
              </button>
            )}
            {this.isAdmin() && (
              <button className="button-admin" onClick={this.handleAdminClick}>
                Admin
              </button>
            )}
            {isOthersView && (
              <button className="button-back" onClick={this.handleBackClick}>
                <img src={require("../img/back-button.png")} alt="Back" />
              </button>
            )}
          </div>
          <div>
            {this.props.auth.isAuthenticated && this.props.auth.user && (
              <p className="welcome">
                Welcome, {this.props.auth.user.email.split("@")[0]}
              </p>
            )}
          </div>
        </div>
        {showMainContent && (
          <div className="frame">
            {sortedProducts.map((product) => {
              const selectedValues = this.state.selectedValues[product.id];
              const { title, description } = this.getTitleAndDescription(product.software);
              const isMumQTOBooster = product.software === "mumqtobooster";
              
              if (!this.checkProductAccess(product.software)) {
                return null;
              } 
                  return (
                  <div className={`tile ${product.software}`} key={product.id}>
                    <div className="logofield">
                      <img
                        className={`logo-${product.software
                          .toLowerCase()
                          .replace(/[-]+/g, "")}`}
                        alt={`Logo ${product.software}`}
                        src={require(`../img/${product.software}-Logo.png`)}
                      />
                    </div>
                    <div className="card-body" >
                      <h1 className="title">{title}</h1>
                      <p className="description">{description}</p>
                    </div>
                    <div className="card-info">
                    {!["mapedit", "exs", "mumbimbooster", "mumqtobooster", "mumautocadbooster"].includes(product.software) && selectedValues && (
                      <>
                        {isMumQTOBooster ? (
                          this.renderQTOBoosterSelects(product.id, selectedValues)
                        ) : (
                          this.renderStandardSelects(product.id, selectedValues)
                        )}
                      </>
                    )}
                    </div>
                    <div className="card-actions">
                      <button
                        className="button-download"
                        onClick={() => {
                          if (!this.props.auth.isAuthenticated) {
                            alert("Please log in to access this feature.");
                          } else if (["mapedit", "exs", "mumbimbooster", "mumqtobooster", "mumautocadbooster"].includes(product.software)) {
                            this.handleOthersClick(`Others${product.software}`);
                          } else {
                            this.handleDownloadClick(product);
                          }
                        }}
                      >
                        DOWNLOAD
                      </button>
                      <button
                        className="button-others"
                        onClick={() => {
                          if (!this.props.auth.isAuthenticated) {
                            alert("Please log in to access this feature.");
                          } else {
                            this.handleOthersClick(
                              `Others${product.software
                                .toLowerCase()
                                .replace(/[-]+/g, "")}`
                            );
                          }
                        }}
                      >
                        ...
                      </button>
                    </div>
                    <div className="card-learnmore">
                      <button
                        className="button-learnmore"
                        onClick={() => this.handleLearnMoreClick(product.software)}
                      >
                        LEARN MORE
                      </button>
                    </div>
                  </div>
                );
            })}
          </div>
        )}
        {Object.entries(this.state).filter(([key, value]) => key.startsWith('showOthers') && value).map(([key]) => {
            const viewName = key.replace('show', '').replace('Content', '');
            if (currentView === viewName) {
              const OthersComponent = this[viewName];
              return <OthersComponent key={viewName} />;
            }
            return null;
          })}
      </div>
    );
  }
}
export default withRouter(Products);
